import Cookies from "js-cookie";
import Vue from "vue";
import App from "./App.vue";
import "./componentConfigs/elementUiConfig";
import "./componentConfigs/veeValidateConfig";
import "./componentConfigs/vueTableConfig";
import router from "./router";
import "@fancyapps/fancybox";
import "@fancyapps/fancybox/dist/jquery.fancybox.css";
// import popper from "popper.js"


Vue.config.productionTip = false;
Vue.prototype.$cookies = Cookies;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
