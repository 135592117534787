import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    // {
    //     path: '/PopUp-Logistics',
    //     component: () => import('../views/PopUp-Logistics.vue')
    // },
    // {
    //     path: '/PopUp-Recipient',
    //     component: () => import('../views/PopUp-Recipient.vue')
    // },
    {
        path: '/',
        name: 'Layout',
        component: () => import('../Layout.vue'),
        children: [
            {
                path: '/',
                name: 'Index',
                component: () => import('../views/Index.vue')
            },
            {
               path: '/Index',
                component: () => import('../views/Index.vue')
            },
            {
                path: '/Category',
                component: () => import('../views/Category.vue')
            },
            {
                path: '/Product',
                component: () => import('../views/Product.vue')
            },
            {
                path: '/Cart',
                component: () => import('../views/Cart.vue')
            },
            {
                path: '/Inquire',
                component: () => import('../views/Inquire.vue')
            },
            {
                path: '/Order',
                component: () => import('../views/Order.vue')
            },
            {
                path: '/OrderDetail',
                component: () => import('../views/OrderDetail.vue')
            },
            {
                path: '/Registration',
                component: () => import('../views/Registration_test.vue')
            },
            {
                path: '/Login',
                component: () => import('../views/Login_test.vue')
            },
            // {
            //     path: '/Links',
            //     component: () => import('../views/Links.vue')
            // },
            // {
            //     path: '/about',
            //     name: 'About',
            //     // route level code-splitting
            //     // this generates a separate chunk (about.[hash].js) for this route
            //     // which is lazy-loaded when the route is visited.
            //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
            // },
            // {
            //     path: '/Search',
            //     component: () => import('../views/SearchResult.vue')
            // },
            // {
            //     path: '/VueTableSample',
            //     name: 'VueTableSample',
            //     component: () => import('../views/VueTableSample.vue')
            // }
        ]
    }
]

const router = new VueRouter({
    mode: "history",routes
})

export default router
