import Vue from 'vue'
import * as rules from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver,extend, localize } from 'vee-validate';
import zhtw from 'vee-validate/dist/locale/zh_TW.json';

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});
localize('zh_TW', zhtw);

//extend("required", {
//    ...required,
//    message: "This field is required"
//});

//extend("email", {
//    ...email,
//    message: "This field must be a valid email"
//});

//extend("confirmed", {
//    ...confirmed,
//    message: "This field confirmation does not match"
//});

//extend("length", {
//    ...length,
//    message: "This field must have 2 options"
//});
